import axios from "axios";
import { getMKTUProducts, getUnitsOfMeasurements } from '@/api/admin'

const state = () => ({
  matchingSuspended: false,
  unitsOfMeasurements: [],
  mktuClassesMap: {},
  mktuProductsMap: {}
})

const mutations = {
  setMatchingSuspended (state, val) {
    state.matchingSuspended = val
  },
  setUnitsOfMeasurements (state, data) {
    state.unitsOfMeasurements = data
  },
  setMKTU (state, mktuClassesMap) {
    state.mktuClassesMap = mktuClassesMap
  },
  setMKTUProducts (state, mktuProductsMap) {
    state.mktuProductsMap = mktuProductsMap
  }
}

const actions = {
  saveAdminItem ({ state, rootState }, { apiMethod, data }) {
    const reqObj = {
      method: 'POST',
      url: `${rootState.backEndAddress}${apiMethod}`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    }
    if (data) reqObj.data = JSON.stringify(data)
    return axios(reqObj)
  },
  async fetchMatchingStatus ({ commit, rootState }) {
    try {
      const response = await axios.get(`${rootState.backEndAddress}/admin/is-matching-suspended`)
      if (response?.data?.status === 200) {
        commit('setMatchingSuspended', response.data.payload)
      }
    } catch (err) {
      console.log({ err })
    }
  },
  async fetchUser ({ rootState }, userId) {
    try {
      const response = await axios.get(`${rootState.backEndAddress}/user/${userId}`)
      if (response?.data?.status === 200) {
        return response.data.payload
      }
    } catch (err) {
      console.log({ err })
      return null
    }
  },
  async fetchUnitsOfMeasurements ({ commit }) {
    try {
      const data = await getUnitsOfMeasurements()
      commit('setUnitsOfMeasurements', data)
    } catch (err) {
      console.log('fetchUnitsOfMeasurements', err)
    }
  },
  async fetchMKTUProducts ({ commit }) {
    try {
      const products = await getMKTUProducts()
      const mktuProductsMap = {}
      const mktuClassesMap = {}
      products.forEach((product) => {
        if (!mktuClassesMap[product.klass]) mktuClassesMap[product.klass] = []
        mktuClassesMap[product.klass].push(product)
        mktuProductsMap[product.number] = product
      }, {})
      commit('setMKTU', mktuClassesMap)
      commit('setMKTUProducts', mktuProductsMap)
    } catch (err) {
      console.log('fetchMKTUProducts', err)
    }
  }
}

const getters = {
  isMatchingSuspended: state => state.matchingSuspended,
  getMKTUCodesByClass: state => classValue => state.mktuClassesMap[classValue],
  getMKTUClasses: state => Object.keys(state.mktuClassesMap),
  getMKTUProducts: state => Object.values(state.mktuClassesMap).flat()
}

export default {
  state,
  mutations,
  actions,
  getters
}
