import { app as Vue } from '@/entry-files/lk'
import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Registration from '../views/Registration.vue'
import SuccesseEmail from '../views/SuccessEmail.vue'
import SuccessRegistration from '../views/SuccessRegistration'
import CEAdmin from "@/views/CEAdmin.vue";
import store from '@/store'

const routes = [
  {
    path: '/lk/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      console.log('LK BEFORE ENTER')
      if (sessionStorage.getItem('app.session.loggedIn') && sessionStorage.getItem('app.session.loggedIn') === 'true') {
        next('/lk/myAccount')
      } else {
        next()
      }
    }
  },
  {
    path: '/lk/registration',
    name: 'registration',
    component: Registration
  },
  {
    path: '/lk/successEmail',
    name: 'successEmail',
    component: SuccesseEmail
  },
  {
    path: '/lk/successRegistration',
    name: 'successRegistration',
    component: SuccessRegistration
  },
  {
    path: '/account',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/home.vue'),
    children: [
      {
        path: '/lk/p2p',
        name: 'P2P',
        component: () => import(/* webpackChunkName: "P2P" */ '@/views/P2PView.vue')
      },
      {
        path: '/lk/p2p/enterDeal',
        name: 'P2PEnterDeal',
        component: () => import(/* webpackChunkName: "P2PEnterDeal" */ '@/views/P2PEnterDealView.vue'),
        beforeEnter: (to, from, next) => {
          if (!store.state.P2P.offerForEnterDeal) next({ name: 'P2P' })
          else next()
        }
      },
      {
        path: '/lk/p2p-deals',
        name: 'P2PDeals',
        component: () => import(/* webpackChunkName: "P2PDeals" */ '@/views/P2PDealsView.vue')
      },
      {
        path: '/lk/p2p-deal',
        name: 'P2PDeal',
        component: () => import(/* webpackChunkName: "P2PDeal" */ '@/views/P2PDealView.vue')
      },
      {
        path: '/lk/admin',
        name: 'Admin',
        component: CEAdmin
      },
      {
        path: '/lk/myAccount',
        name: 'My account',
        component: () => import(/* webpackChunkName: "myAccountMain" */ '../views/Lk-Main22.vue'),
        beforeEnter: (to, from, next) => {
          const { query } = from
          const { path } = to
          const isQueryEqual = JSON.stringify(query) === JSON.stringify(to.query)
          if (Object.keys(query).includes('cashierId') && !isQueryEqual) {
            next({ path, query })
          } else next()
        }
      },
      // {
      //   path: '/autoexchange',
      //   name: 'Autoexchange',
      //   component: () => import(/* webpackChunkName: "Autoexchange" */ '../views/Autoexchange.vue')
      // },
      {
        path: '/lk/autoexchange',
        name: 'Autoexchange',
        component: () => import(/* webpackChunkName: "Autoexchange" */ '../views/AutoExchangeV2.vue')
      },
      {
        path: '/lk/transfer',
        name: 'transfer',
        component: () => import(/* webpackChunkName: "transfer" */ '../views/Transfer.vue')
      },
      {
        path: '/lk/statement',
        name: 'statement',
        component: () => import(/* webpackChunkName: "statement" */ '../views/Statement.vue')
      },
      {
        path: '/lk/exchange',
        component: () => import(/* webpackChunkName: "exchange" */ '../views/Exchange.vue'),
        children: [
          {
            path: '',
            name: 'exchange',
            redirect: '/lk/exchange/withuser'
          },
          {
            path: '/lk/exchange/withuser',
            name: 'exchangeWithUser',
            component: () => import(/* webpackChunkName: "exchangewithuser" */ '../views/ExchangeWithUser.vue')
          }
        ]
      },
      {
        path: '/lk/input',
        name: 'input',
        component: () => import(/* webpackChunkName: "input" */ '../views/Input.vue')
      },
      {
        path: '/lk/output',
        name: 'output',
        component: () => import(/* webpackChunkName: "output" */ '../views/Output.vue')
      },
      {
        path: '/lk/requests',
        component: () => import(/* webpackChunkName: "requests" */ '../views/Requests.vue'),
        children: [
          {
            path: '',
            name: 'requests',
            redirect: '/lk/requests/inRequests'
          },
          {
            path: '/lk/requests/inRequests',
            name: 'In request',
            component: () => import(/* webpackChunkName: "inrequests" */ '../views/InRequests.vue')
          },
          {
            path: '/lk/requests/outRequests',
            name: 'Out request',
            component: () => import(/* webpackChunkName: "outrequests" */ '../views/OutRequests.vue')
          }
        ]
      },
      {
        name: 'manage',
        path: '/lk/manage',
        component: () => import(/* webpackChunkName: "manage" */ '../views/Manage/Manage.vue')
      },
      {
        path: '/lk/referral',
        name: 'referral',
        component: () => import(/* webpackChunkName: "referral" */ '../views/Manage/ReferralPrograms.vue')
      },
      {
        path: '/lk/kyc/personal',
        name: 'kyc',
        component: () => import(/* webpackChunkName: "kyc" */ '@/views/Manage/NewKyc/index.vue'),
        children: [
          {
            path: '/lk/kyc/personal',
            name: 'Personal KYC',
            component: () => import(/* webpackChunkName: "personalKyc" */ '@/views/Manage/NewKyc/PersonalKyc.vue')
          }
          // {
          //   path: '/kyc/company',
          //   name: 'Company KYC',
          //   component: () => import(/* webpackChunkName: "companyKyc" */ '../views/Manage/Kyc/CompanyKyc.vue')
          //
          // }
        ]
      },
      {
        path: '/lk/externalwallets',
        name: 'externalwallets',
        component: () => import(/* webpackChunkName: "externalwallets" */ '../views/Manage/ExternalWallets.vue')
      },
      {
        path: '/lk/buyLions',
        name: 'BuyLions',
        component: () => import(/* webpackChunkName: "BuyLions" */ '../views/BuyLions2.vue')
      },
      {
        path: '/lk/cashier',
        name: 'cashierView',
        component: () => import(/* webpackChunkName: "cashierView" */ '@/components/Cashier/CashierView.vue')
      },
      {
        path: '/lk/root-cashier',
        name: 'rootCashierView',
        component: () => import(/* webpackChunkName: "rootCashierView" */ '@/components/RootCashier/RootCashierView.vue')
      },
    ],
    beforeEnter: (to, from, next) => {
      if (sessionStorage.getItem('app.session.loggedIn') && sessionStorage.getItem('app.session.loggedIn') === 'true') {
        next()
      } else if (to.name !== 'login') {
        next({ path: '/lk/login', query: to.query })
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { left: 0, top: 0 }
    }
  },
  routes,
})
router.beforeEach((to, from, next) => {
  if (!to.href.includes('lk')) window.location.href = to.path
  else next()
})

router.afterEach((to, from) => {
  Vue.sessionRefresh()
  if (to.hash) {
    return { selector: to.hash }
  } else {
    return { left: 0, top: 0 }
  }
})

export default router
